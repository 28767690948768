@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912161/fonts/marlin-soft-001-thin-webfont_ysyycl.woff2') format('woff2');
    font-weight: 50;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912161/fonts/marlin-soft-002-extra-light-webfont_nj2zei.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912169/fonts/marlin-soft-003-light-webfont_ikyr72.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912170/fonts/marlin-soft-004-book-webfont_ejnkp6.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912172/fonts/marlin-soft-005-regular-webfont_y0gbxc.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912172/fonts/marlin-soft-005-regular-webfont_y0gbxc.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912171/fonts/marlin-soft-007-bold-webfont_tsu0om.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912183/fonts/marlin-soft-008-extra-bold-webfont_mpi76h.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912182/fonts/marlin-soft-009-black-webfont_trpdkm.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912183/fonts/marlin-soft-010-extra-black-webfont_zuhsbz.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912186/fonts/marlin-soft-italic-001-thin-webfont_f0dnai.woff2') format('woff2');
    font-weight: 50;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912188/fonts/marlin-soft-italic-002-extra-light-webfont_rtexyi.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912190/fonts/marlin-soft-italic-003-light-webfont_elvprf.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912186/fonts/marlin-soft-italic-004-book-webfont_yum3y6.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912187/fonts/marlin-soft-italic-005-regular-webfont_uynkye.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912188/fonts/marlin-soft-italic-006-medium-webfont_ykrofh.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912191/fonts/marlin-soft-italic-007-bold-webfont_krbfns.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912192/fonts/marlin-soft-italic-008-extra-bold-webfont_lg5sdz.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912191/fonts/marlin-soft-italic-009-black-webfont_bkt1lu.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MarlinSoft';
    src: url('https://res.cloudinary.com/gradivo-hr/raw/upload/v1730912193/fonts/marlin-soft-italic-010-extra-black-webfont_qwn05r.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

:root {
    --font-marlinsoft-cloudinary: 'MarlinSoft', sans-serif;
}