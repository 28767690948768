@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --primary-red: 352 94% 60%;
        --primary-red-accent: 352 100% 66%;
        --primary-red-foreground: 0 0% 100%;
        --primary-blue: 220 100% 36%;
        --primary-blue-accent: 220 100% 28%;
        --primary-blue-foreground: 0 0% 100%;
        --primary-yellow: 47% 100% 50%;
        --primary-yellow-accent: 48 94% 77%;
        --primary-yellow-foreground: 0 0% 14%;

        --noise-light: url('/img/grain-light.png');
        --noise-dark: url('/img/grain.svg');
        --pattern: url('/img/pattern.svg');
        --pattern-gradient-white: rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%;
        --pattern-gradient-blue: rgba(0, 63, 185, 0.1) 0%, rgba(0, 63, 185, 1) 100%;
        --pattern-gradient-yellow: rgba(255, 199, 0, 0.1) 0%, rgba(255, 199, 0, 1) 100%;
        --pattern-gradient-dark: rgba(28, 27, 39, 0) 0%, rgba(28, 27, 39, 0) 40%, rgba(28, 27, 39, 0.5) 100%;
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --background-dark: 244 79% 3%;
        --foreground-dark: 0 0% 100%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 352 85% 60%;
        --destructive-foreground: 210 40% 98%;

        --success: 142 70% 60%;
        --success-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;

        --radius: 0.5rem;

        --gray-800: 0 0% 14%;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;
        --primary-gradient: linear-gradient(224deg, #e89d39 16.79%, #fff 102.79%);

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

html,
body {
    padding: 0;
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

mjx-container {
    display: inline-block;

    svg {
        display: inline;
    }
}

.bg-purple-gradient {
    background: linear-gradient(90deg, #7912b2 -95.45%, #cf1da1 100%);
}

.border-purple-gradient {
    position: relative;
}

.border-purple-gradient::before {
    border: double 2px transparent;
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, #7912b2 -95.45%, #cf1da1 100%);
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: inherit;
}

.text-purple-gradient {
    background-image: linear-gradient(90deg, #7912b2 -95.45%, #cf1da1 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.pattern-fade-in {
    opacity: 0;
    animation: fadeIn 700ms ease-in-out forwards;
}

.gradient-separator-fade-in {
    opacity: 0;
    animation: fadeIn 1000ms ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.widget__actor {
    bottom: 78px !important;
}

.fill-available {
    max-height: fit-content;
    max-height: -webkit-fill-available;
}

[data-tooltip]:before,
[data-tooltip]:after {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    transition: 0.3s ease-in-out 0.2s;
}

[data-tooltip]:before {
    content: '';
    height: 0;
    width: 0;
    top: calc(100%);
    border-style: solid;
    border-width: 4px;
    border-color: transparent transparent rgb(var(--gray-800)) transparent;
}

[data-tooltip]:after {
    content: attr(data-tooltip);
    white-space: nowrap;
    background: rgb(var(--gray-800));
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    font-size: 0.75rem;
    box-sizing: border-box;
    top: calc(100% + 8px);
    color: #fff;
    border-radius: 4px;
    text-shadow: 0px 1px 1px #000;
    z-index: 9;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    opacity: 1;
    visibility: visible;
    transition: 0.2s ease-in-out 0.3s;
}