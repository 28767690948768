@use './global.scss' as *;
@use './animations.scss' as *;
@use './text.scss' as *;
@use './cookie-consent.scss' as *;
@use "../print-styles/marlin-fonts" as *;

.sidebar-trigger {
    transition:
        width 0.15s ease-in-out,
        background 0.3s ease !important;

    &--in {
        width: 148px !important;

        &:before {
            content: 'Pogledaj više ';
            font-size: 15px !important;
            margin-right: 14px;
        }
    }
}

body {
    --font-family: "MarlinSoft";
    --font-marlinsoft: 400;
    font-family: var(--font-family);
}